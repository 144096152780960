import { useFetch } from 'hooks/useFetch';
import queryString from 'query-string';
import { apiResponseSchema } from 'schemas/api-response/apiResponse';
import { WaitingListEntriesForUserApiResponse, waitingListEntryForUserArraySchema } from 'schemas/waiting-lists/WaitingListEntryForUser';
import { WaitingListEntryApiResponse, waitingListEntrySchema } from 'schemas/waiting-lists/WaitingListEntryResponse';
import { WaitingListOfferApiResponse, WaitingListOfferResponseEnum, waitingListOfferSchema } from 'schemas/waiting-lists/WaitingListOfferResponse';
import { WaitingListUserCompaniesApiResponse, waitingListUserCompaniesArraySchema } from 'schemas/waiting-lists/WaitingListUserCompanies';
import { API_BASE_URL } from 'utils/constants';

interface PostRespondToOffer {
  token: string;
  response: WaitingListOfferResponseEnum;
}

export interface UpdatedWaitingListEntry {
  sizeMin?: number;
  sizeMax?: number;
  priceMin?: number;
  priceMax?: number;
  roomsMin?: number;
  roomsMax?: number;
  cprNumber?: string;
  pensionMembership?: 'SAMPENSION';
  status?: 'ACTIVE' | 'PASSIVE';
}

export const useWaitingListApi = () => {
  const { fetch } = useFetch();

  const getWaitingListOffer = async (token: string): Promise<WaitingListOfferApiResponse> => {
    const response = await fetch(`${API_BASE_URL}/waiting-list/offer/${token}`, {
      method: 'GET',
      credentials: 'include',
    });

    const result = await response.json();
    const offerResponseSchema = apiResponseSchema(waitingListOfferSchema);
    const parsedResult = offerResponseSchema.safeParse(result);
    if (parsedResult.success) {
      return parsedResult.data;
    }
    throw parsedResult.error;
  };

  const postRespondToOffer = async (data: PostRespondToOffer) => {
    await fetch(`${API_BASE_URL}/waiting-list/offer/${data.token}/respond`, {
      method: 'POST',
      body: JSON.stringify({ response: data.response }),
    });
  };

  const leaveWaitingList = async (waitingListEntryId: string) => {
    await fetch(`${API_BASE_URL}/waiting-list/${waitingListEntryId}/leave`, {
      method: 'POST',
    });
  };

  const getWaitingListEntryForPropertyGroup = async (propertyGroupId: string): Promise<WaitingListEntryApiResponse> => {
    const response = await fetch(`${API_BASE_URL}/waiting-list?propertyGroupId=${propertyGroupId}`, {
      method: 'GET',
      credentials: 'include',
    });

    const result = await response.json();
    const waitingListEntryResponseSchema = apiResponseSchema(waitingListEntrySchema);
    const parsedResult = waitingListEntryResponseSchema.safeParse(result);
    if (parsedResult.success) {
      return parsedResult.data;
    }
    throw parsedResult.error;
  };

  const updateWaitingListEntry = async (waitingListEntryId: string, data: UpdatedWaitingListEntry): Promise<WaitingListEntryApiResponse> => {
    const response = await fetch(`${API_BASE_URL}/user/waiting-list-entries/${waitingListEntryId}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });

    const result = await response.json();
    const waitingListEntryResponseSchema = apiResponseSchema(waitingListEntrySchema);
    const parsedResult = waitingListEntryResponseSchema.safeParse(result);
    if (parsedResult.success) {
      return parsedResult.data;
    }
    throw parsedResult.error;
  };

  const getWaitingListEntriesForUser = async (whiteLabelCompanyIds?: string[], companyId?: string): Promise<WaitingListEntriesForUserApiResponse> => {
    const url = queryString.stringifyUrl(
      {
        url: 'user/waiting-list-entries',
        query: { whiteLabelCompanyIds, companyId },
      },
      { arrayFormat: 'comma', skipEmptyString: true },
    );
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      method: 'GET',
    });

    const result = await response.json();
    const waitingListEntriesForUserResponseSchema = apiResponseSchema(waitingListEntryForUserArraySchema);
    const parsedResult = waitingListEntriesForUserResponseSchema.safeParse(result);
    if (parsedResult.success) {
      return parsedResult.data;
    }
    throw parsedResult.error;
  };

  const getWaitingListCompaniesForUser = async (whiteLabelCompanyIds?: string[]): Promise<WaitingListUserCompaniesApiResponse> => {
    const url = queryString.stringifyUrl(
      {
        url: 'user/waiting-list-companies',
        query: { whiteLabelCompanyIds },
      },
      { arrayFormat: 'comma' },
    );
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      method: 'GET',
    });

    const result = await response.json();
    const waitingListUserCompaniesArrayResponseSchema = apiResponseSchema(waitingListUserCompaniesArraySchema);
    const parsedResult = waitingListUserCompaniesArrayResponseSchema.safeParse(result);
    if (parsedResult.success) {
      return parsedResult.data;
    }
    throw parsedResult.error;
  };
  return {
    getWaitingListOffer,
    postRespondToOffer,
    getWaitingListEntryForPropertyGroup,
    leaveWaitingList,
    updateWaitingListEntry,
    getWaitingListEntriesForUser,
    getWaitingListCompaniesForUser,
  };
};
