import styled from 'styled-components';
import { Heading } from 'legacy-ui';
import PropertyGroupOverviewListItem from '../../components/PropertyGroupOverviewListItem';
import { Text } from 'legacy-ui';
import { useAnalytics } from '../../hooks/useAnalytics';
import { PropertyGroupOverviewAPIResult, SearchAPIResult } from '../../types/api/searchTypes';
import { GeneralCMSResult } from '../../types/cms/generalTypes';
import { SearchCMSResult } from '../../types/cms/searchTypes';
import { PROPERTY_AVAILABLE } from '../../utils/constants';
import WaitingListPropertyGroupOverviewListItem from '../../components/WaitingListPropertyGroupOverviewListItem';
import { PropertyGroupStatus } from '../../types/api/propertyGroupTypes';

export const NoResults = styled.div`
  padding: 32px 0;
  text-align: center;
  h3 {
    margin-bottom: 16px;
  }
`;

interface SearchResultListProps {
  data?: SearchAPIResult;
  generalTexts: GeneralCMSResult;
  fromCMS: SearchCMSResult;
}

function hasAvailableProperties(overview: PropertyGroupOverviewAPIResult): boolean {
  return overview.properties.some((property) => property.transactionStatus === PROPERTY_AVAILABLE);
}

function availablePropertiesComparator(overview: PropertyGroupOverviewAPIResult): number {
  return hasAvailableProperties(overview) ? -1 : 1;
}

function sortResults(data: PropertyGroupOverviewAPIResult[]) {
  return data.sort((a, b) => availablePropertiesComparator(a) - availablePropertiesComparator(b));
}

const SearchResultList = ({ data, generalTexts, fromCMS }: SearchResultListProps) => {
  useAnalytics('Search List', undefined, 'track', !data);

  if (!data) {
    return null;
  }
  if (data.searchResults.length === 0 && data.searchResultsWithoutAvailableProperties.length === 0) {
    return (
      <NoResults>
        <Heading tag="h3" size="xxs">
          {fromCMS.ResultsNoMatchHeadline}
        </Heading>
        <Text>{fromCMS.ResultsNoMatchDescription}</Text>
      </NoResults>
    );
  }

  const sortedResults = sortResults(data.searchResults);
  return (
    <div>
      {[...sortedResults, ...data.searchResultsWithoutAvailableProperties].map((item, index) =>
        item.propertyGroup.status == PropertyGroupStatus.WaitingList ? (
          <WaitingListPropertyGroupOverviewListItem propertyGroupOverview={item} key={index} />
        ) : (
          <PropertyGroupOverviewListItem propertyGroupOverview={item} generalTexts={generalTexts} fromCMS={fromCMS} key={index} />
        ),
      )}
    </div>
  );
};

export default SearchResultList;
