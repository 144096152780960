import { apiResponseSchema } from 'schemas/api-response/apiResponse';
import { z } from 'zod';

export const waitingListEntrySchema = z.object({
  id: z.string(),
  propertyGroupId: z.string(),
  userId: z.string(),
  priceMin: z.number().nullable().optional(),
  priceMax: z.number().nullable().optional(),
  sizeMin: z.number().nullable().optional(),
  sizeMax: z.number().nullable().optional(),
  roomsMin: z.number().nullable().optional(),
  roomsMax: z.number().nullable().optional(),
  cprNumber: z.string().nullable().optional(),
  pensionMembership: z.string().nullable().optional(),
  addedOn: z.string(),
  updatedOn: z.string(),
  status: z.string(),
});

export type WaitingListEntryResponse = z.infer<typeof waitingListEntrySchema>;
export type WaitingListEntryApiResponse = z.infer<ReturnType<typeof apiResponseSchema<typeof waitingListEntrySchema>>>;
