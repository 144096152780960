import { z } from 'zod';

export function apiResponseSchema<T extends z.ZodTypeAny>(data: T) {
  return z.object({
    timestamp: z.string(),
    data: data.nullable().optional(),
  });
}

export function apiPaginatedResponseSchema<T extends z.ZodTypeAny>(data: T) {
  return z.object({
    timestamp: z.string(),
    data: z.array(data),
    pagination: z.object({
      page: z.number(),
      pageSize: z.number(),
      total: z.number(),
      pageCount: z.number(),
      hasNextPage: z.boolean(),
      hasPreviousPage: z.boolean(),
    }),
  });
}

export type ApiResponse<T extends z.ZodTypeAny> = z.infer<ReturnType<typeof apiResponseSchema<T>>>;
export type ApiPaginatedResponse<T extends z.ZodTypeAny> = z.infer<ReturnType<typeof apiPaginatedResponseSchema<T>>>;
