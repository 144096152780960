import { faBedFront, faMapMarkerAlt, faTableCellsLarge, faWallet } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import defaultCompany from '../../assets/default-company.svg';
import logoWhite from '../../assets/logo-white.svg';
import { PropertyGroupOverviewAPIResult } from '../../types/api/searchTypes';
import { LanguageKey } from '../../types/common';
import { PROPSTEP } from '../../utils/constants';
import { getImageFromApiURl } from '../../utils/http';
import { getCompanyLink, getPropertyGroupLink } from '../../utils/links';
import { Heading, Text } from 'legacy-ui';
import {
  OverviewHeader,
  OverviewHeaderDetails,
  OverviewHeaderDetailsCompany,
  OverviewHeaderImage,
  OverviewHeaderImageInner,
  OverviewHeaderImageInnerLabel,
  OverviewWrapper,
} from './styles';
import { useWhiteLabel } from 'providers/WhiteLabelProvider';
import PropertyImageThumbnail from 'components/PropertyImageThumbnail';
import { useTranslation } from 'next-i18next';
import { formatCurrencyValue } from '../../utils/currency';
import WaitingListButton from '../WaitingList/WaitingListButton';

interface PropertyGroupListItemProps {
  propertyGroupOverview: PropertyGroupOverviewAPIResult;
}

const WaitingListPropertyGroupOverviewListItem = ({ propertyGroupOverview }: PropertyGroupListItemProps) => {
  const { propertyGroup, company, properties } = propertyGroupOverview;
  const [firstImage] = propertyGroup.images;
  const { theme } = useWhiteLabel();
  const router = useRouter();
  const { locale } = router;
  const propertyGroupLink = `${getPropertyGroupLink(propertyGroup, locale as LanguageKey)}`;
  const sizeRange = useMemo(() => {
    const sizes = properties.map((property) => property?.propertyDetails?.size).filter((size) => size);
    const min = Math.min(...sizes);
    const max = Math.max(...sizes);
    return min === max ? min : `${min}-${max}`;
  }, [properties]);
  const roomsRange = useMemo(() => {
    const sizes = properties.map((property) => property?.propertyDetails?.rooms).filter((size) => size);
    const min = Math.min(...sizes);
    const max = Math.max(...sizes);
    return min === max ? min : `${min}-${max}`;
  }, [properties]);
  const priceRange = useMemo(() => {
    const sizes = properties.map((property) => property?.transactionDetails?.price).filter((size) => size);
    const min = formatCurrencyValue(Math.min(...sizes) / 100, locale);
    const max = formatCurrencyValue(Math.max(...sizes) / 100, locale);
    return min === max ? min : `${min}-${max}`;
  }, [locale, properties]);

  const { t } = useTranslation('search');
  return (
    <OverviewWrapper>
      <OverviewHeader propstepManaged={propertyGroup.handledBy === PROPSTEP}>
        <OverviewHeaderImage>
          <OverviewHeaderImageInner>
            <Link href={propertyGroupLink} passHref>
              <a>
                <PropertyImageThumbnail
                  src={getImageFromApiURl(firstImage?.name)}
                  alt={propertyGroup.name}
                  backgroundColor={theme.color.propertyCardBackgroundColor}
                  logoColor={theme.color.propertyCardLogoColor}
                />
                {propertyGroup.upcomingProject && (
                  <OverviewHeaderImageInnerLabel>
                    <Text size="s">{t('Upcoming')}</Text>
                  </OverviewHeaderImageInnerLabel>
                )}
              </a>
            </Link>
          </OverviewHeaderImageInner>
        </OverviewHeaderImage>
        <OverviewHeaderDetails>
          <Heading size="xs" tag="h2">
            <Link href={propertyGroupLink} passHref>
              <a>{propertyGroup.name}</a>
            </Link>
          </Heading>
          <p className={'pt-1 text-xs'}>{t('{{count}} properties in total', { count: properties.length })}</p>
          <div>
            <div className={'mb-10 mt-7 flex flex-row items-center'}>
              <div className={'mr-14 flex flex-col'}>
                <div className={'flex flex-row items-center pb-1'}>
                  <FontAwesomeIcon className={'w-5 pr-3.5 text-xl'} icon={faMapMarkerAlt} />
                  <p className={'font-normal'}>{propertyGroup?.location?.city}</p>
                </div>
                <div className={'flex flex-row items-center'}>
                  <FontAwesomeIcon className={'w-5 pr-3.5 text-xl'} icon={faTableCellsLarge} />
                  <p className={'font-normal'}>{t('{{sizeRange}}m²', { sizeRange })}</p>
                </div>
              </div>
              <div className={'mr-12 flex flex-col'}>
                <div className={'flex flex-row items-center pb-1'}>
                  <FontAwesomeIcon className={'w-5 pr-3.5 text-xl'} icon={faBedFront} />
                  <p className={'font-normal'}>{t('{{roomsRange}} rooms', { roomsRange })}</p>
                </div>
                <div className={'flex flex-row items-center'}>
                  <FontAwesomeIcon className={'w-5 pr-3.5 text-xl'} icon={faWallet} />
                  <p className={'font-normal'}>{t('{{priceRange}} DKK', { priceRange })}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={'flex flex-row items-center justify-between'}>
            <div>
              <Link href={getCompanyLink(company, locale as LanguageKey)} passHref>
                <a>
                  <OverviewHeaderDetailsCompany>
                    <div>
                      <Image src={company.logo ? getImageFromApiURl(company.logo?.name) : defaultCompany} width={20} height={20} alt={company.name} />
                    </div>
                    <div>
                      <Text size="s" weight="m">
                        {company.name}
                      </Text>
                    </div>
                  </OverviewHeaderDetailsCompany>
                </a>
              </Link>
              {propertyGroup.handledBy === PROPSTEP && (
                <div className={'mt-1 flex flex-row items-center'}>
                  <div className={'mr-1'}>
                    <Text size="s" weight="m">
                      {t('Managed by')}
                    </Text>
                  </div>
                  <div>
                    <Image src={logoWhite} alt="Propstep" width="70" height="12" />
                  </div>
                </div>
              )}
            </div>
            <div>
              <WaitingListButton propertyGroup={propertyGroup} leaveButtonColor={'danger'} joinButtonColor={'primary'} />
            </div>
          </div>
        </OverviewHeaderDetails>
      </OverviewHeader>
    </OverviewWrapper>
  );
};

export default WaitingListPropertyGroupOverviewListItem;
